.header-wrapper {
  background: none;
}

.header,
%header {
  display: block;

  @include zen-respond-to(xl) {
    display: grid;
    grid-template-columns: max-content auto max-content;
  }

  &__logo {
    display: block;
    text-align: center;
    width: auto;
  }

  &__logo-image {
    // width: 300px;
  }

  &__name-and-slogan {
    text-shadow: 0 0 5px #000;
    color: #fff;
    font-weight: bold;
    margin: 0 70px;
    // margin-top: 20px;
    // line-height: 2em;
    text-align: center;
  }

  &__site-slogan {
    @include font-size(xl);
  }

  &__site-name {
    font-size: 220%;
    letter-spacing: -0.05em;
  }
}

footer {
  background: color(veil);
}


.views-field-field-currency .field-content {
  color: #333;
}
