.social-networks,
.share {

  a {
    color: color('blue');

    &:hover,
    &:focus {
      color: lighten(color('blue'), 20);
    }
  }
}

#block-iorgos-core-phone {
  float: none;

  a {
    color: color('blue');

    &:hover,
    &:focus {
      color: lighten(color('blue'), 20);
    }
  }
}
