body {
  background: url("../images/background.jpg") no-repeat top;
  background-attachment: fixed;
  background-size: cover;
}

a {
  color: #143c84;
}

a:visited, a:active {
  color: #11326e;
}

.social-networks a,
.share a {
  color: #143c84;
}

.social-networks a:hover, .social-networks a:focus,
.share a:hover,
.share a:focus {
  color: #2164dd;
}

#block-iorgos-core-phone {
  float: none;
}

#block-iorgos-core-phone a {
  color: #143c84;
}

#block-iorgos-core-phone a:hover, #block-iorgos-core-phone a:focus {
  color: #2164dd;
}

.buscador select:hover, .buscador select:active, .buscador select:focus {
  border-color: #143c84;
}

.buscador .form-type-select::after {
  color: #143c84;
}

.buscador .form-submit {
  background: #143c84;
}

.buscador .form-submit:hover, .buscador .form-submit:focus {
  background: #1b50b0;
}

.header-wrapper {
  background: none;
}

.header {
  display: block;
}

@media (min-width: 999px) {
  .header {
    display: grid;
    grid-template-columns: max-content auto max-content;
  }
}

.header__logo {
  display: block;
  text-align: center;
  width: auto;
}

.header__name-and-slogan {
  text-shadow: 0 0 5px #000;
  color: #fff;
  font-weight: bold;
  margin: 0 70px;
  text-align: center;
}

.header__site-slogan {
  font-size: 1.5rem;
}

.header__site-name {
  font-size: 220%;
  letter-spacing: -0.05em;
}

footer {
  background: rgba(255, 255, 255, 0.75);
}

.views-field-field-currency .field-content {
  color: #333;
}

.views-field-field-currency .field-content {
  color: #999;
}

.views-field-field-currency .field-content a {
  color: #999;
}

.region-navigation .block {
  border-top: 1px solid #143c84;
  border-bottom: 1px solid #143c84;
}

.region-navigation .block .menu li a {
  color: #000;
  background: #fff;
}

.region-navigation .block .menu li a:active {
  background: #e6e6e6;
}

@media (min-width: 555px) {
  .region-navigation .block .menu li a {
    background: none;
    border: 0;
  }
  .region-navigation .block .menu li a.active, .region-navigation .block .menu li a:hover {
    background: none;
    text-decoration: underline;
  }
  .region-navigation .block .menu li a:active {
    background: #e6e6e6;
  }
}

@media (min-width: 555px) {
  .region-navigation .block {
    background: #fff;
  }
}

[type='submit'] {
  background: #143c84;
}

[type='submit']:hover {
  background: #1b50b0;
}
