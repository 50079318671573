.navbar {

  li {

    @include zen-respond-to(xs) {
      // border: 0;
    }

    // border: 1px solid color(link);
  }
}

.region-navigation .block {
  border-top: 1px solid color('blue');
  border-bottom: 1px solid color('blue');

  .menu li a {
    color: #000;
    background: #fff;
    // border: 1px solid color('yellow');

    &.active,
    &:active,
    &:hover {
      // background: lighten(color(main), 10);
      // text-decoration: none;
      // text-decoration: underline;
      // color: color('yellow');
    }

    &:active {
      background: darken(#fff, 10);
    }

    @include zen-respond-to(xs) {
      background: none;
      border: 0;

      &.active,
      &:hover {
        background: none;
        // text-decoration: none;
        text-decoration: underline;
      }

      &:active {
        background: darken(#fff, 10);
      }
    }
  }

  @include zen-respond-to(xs) {
    // background: color(primary);
    background: #fff;
  }
}
