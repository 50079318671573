.buscador {

  select {

    &:hover,
    &:active,
    &:focus {
      border-color: color('blue');
    }
  }

  .form-type-select::after {
    color: color('blue');
  }

  .form-submit {
    background: color('blue');

    &:hover,
    &:focus {
      background: lighten(color('blue'), 10);
    }
  }
}
